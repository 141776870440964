import { useQuery } from "@tanstack/react-query";
import { getBreakCount } from "../../services/count/apiBreakCount";

export default function BreakCountQuery(date, empId) {
  const { data, refetch } = useQuery({
    queryKey: ["breakCount", date, empId],
    queryFn: () => getBreakCount(date, empId),
  });

  return {
    breakCount: data?.data?.short_break_count,
    breakCountRefetch: refetch,
  };
}
