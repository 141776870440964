import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { ViewNotice } from "../../utils/reactQuery/notice/ViewNotice";
import CloseIcon from "@mui/icons-material/Close";
import { ViewPolicy } from "../../utils/reactQuery/policy/ViewPolicy";
import { useResponsive } from "../../hooks/customUseResponsive";
import { ViewManualMessageQuery } from "../../utils/reactQuery/attendance/ViewManualMessage";
import { ViewFeedbackQuery } from "../../utils/reactQuery/manager/EmployeeFeedback/ViewFeedback";

export default function NotesPopup({ openPop, handleClose, id, policy, manual, feedback}) {
  const isMobile = useResponsive('down','sm');

  const { note, isLoading } = manual
    ? ViewManualMessageQuery(id)
    : feedback
    ? ViewFeedbackQuery(id)
    : policy
    ? ViewPolicy({ id })
    : ViewNotice({ id });
  
// console.log("note ===", note)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? '330px' : 507,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: '90vh', // Ensures the modal doesn't exceed the viewport height
    overflowY: 'auto',
  };
  return (
    <Modal open={openPop} onClose={() => handleClose()}>
      <Box sx={{ ...style }}>
      <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>Notes</Typography>
          <Box onClick={()=>handleClose()}>
          <CloseIcon className={styles.icon}/>
          </Box>
        </Stack>
        {isLoading ? <Stack className={styles.loadingCont}>
        <CircularProgress/>
        </Stack> : <Box>
          <Typography className={styles.noteHead}>{note?.policy_name}</Typography>
          <div  dangerouslySetInnerHTML={{ __html: note?.description}} className={styles.noteDescription}>
        </div>
        </Box>}
      </Box>
    </Modal>
  );
}
