import { useState } from "react";
import { Box, Button, Popover } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function DropdownPopover({
  buttonLabel,
  isTimer = false,
  children,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      {/* Button to open the dropdown */}
      <Button
        onClick={handleClick}
        variant="outlined"
        sx={{
          borderRadius: 5,
          textTransform: "capitalize",
        }}
      >
        {buttonLabel}
        {isTimer ? <AccessTimeIcon /> : <ExpandMore />}
      </Button>

      {/* Popover acting as a dropdown */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{minWidthidth: 350}}
      >
        <Box p={2}>
          {children}
        </Box>
      </Popover>
    </Box>
  );
}
