import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

import "./styles/css/global.scss";
import { Toaster } from "react-hot-toast";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/Theme";
import { Box, Button } from "@mui/material";
import { localStorageInstance } from "./utils/localStorage";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Router from "./router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000 * 10,
    },
  },
});

// localStorageInstance.setItem({key: 'token', value: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODRhZjc4ODZmNGY4N2EwYTFhYTAxNDgwYjg0NGNkNmFjMWU2M2Q0NzAwYzlmYzFiZTE4M2M5NzFkZWFiOTVjMzk5YzZjMDg4MmE5ZWJiZjAiLCJpYXQiOjE3MTcxNjMwMjQuNjI4NDI4LCJuYmYiOjE3MTcxNjMwMjQuNjI4NDMyLCJleHAiOjE3NDg2OTkwMjQuNjIwODgzLCJzdWIiOiIzMzYiLCJzY29wZXMiOltdfQ.Hy9qV8HCgU5_1mrnk4b-XWkpmxDl7hvNC3ZxX0YcjK5r0u5wEJD9Gq9CucjgiSf4duu7jJ31yJa-ITgAUeeo7-sT-3dIisDtRWQXg8DJVrj5VmNobK0O6Rg4ZXWHZxVrDjM0-i-99kwTh9Ezxz7OvWR_x-AoJlvrhXBBrV5y0ZDN8lrrm4adNOF73I7IApPcjuxQDDe6yR3kWxgPSVmlQHDBX4u-9iLNOfgpnfB7erVffRRu4nuZia8LjcVRzRqQQ9OnmFFBRQ7UCIiv6payqTiVqUJTJw2Bv3GMUaUoNo0W0TjL8_7Q5esGV0JKK1Z1tobq1McOLAnCyldjHt6hCgROf67PqiTaR9QdYTzw32w7HYV8ZFSLwyQyBGyVzD4B_9wZmzkcHmb6zo5OszWiv4XmZk92xTe64OYlcmp4QLAQwm9WBQcxiReIuWCEThzZOs_iT0ai4sKr6iGMqNNB5s9sIoP8VVcrWcghSReCxZAukcyiNA1tRi0xTAo_wfAdnZKJF8aRZSreLTR9_cPZmcgMX52u1bcGiIJVzpsw2KzvPLKRkvkF7OETUcR_hZ3KNHIaLMIutU3x-Lac87bM6lB8j4HTtDE4FNKrB7NbGS4xus010DJwTfu57JanoIoztBnFTKYLXfdExTEyV8c09fcGekTwYdOeIfgk8nohclc"})
// localStorageInstance.setItem({ key: "role", value: "admin" });

function App() {
  return (
    <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
        <Router/>
          <Toaster
            position="top-center"
            gutter={12}
            containerStyle={{ margin: "8px" }}
            toastOptions={{
              success: {
                duration: 3000,
              },
              error: {
                duration: 5000,
              },
              style: {
                fontSize: "16px",
                maxWidth: "500px",
                padding: "16px 24px",
                backgroundColor: "white",
                color: "black",
              },
            }}
          />
          {/* <Navbar/>
          <Box className='mainContainer'>
            <Dashboard/>
          </Box> */}

        </ThemeProvider>
      </QueryClientProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
