import Box from "@mui/material/Box";
import TableHeader from "../../../components/TableHeader/TableHeader";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import AttendanceRegister from "../../../components/AttendanceRegister/AttendanceRegister";
import styles from "./styles.module.css";

export default function AttendanceRegisterReport() {
  const { currentEmployee, emplist, onCurrentEmployeeChange } =
    useEmployeeSelection();

  // console.log("attendance register current employee", currentEmployee);
  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="Attendance Register"
        currentEmployee={currentEmployee.employee_id}
        emplist={emplist}
        handleChange={onCurrentEmployeeChange}
      />
      <AttendanceRegister currentEmployeeId={currentEmployee.employee_id} />
    </Box>
  );
}
