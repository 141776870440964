import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import { useState } from "react";
import Default from "../Avatar";
import AccountPopOver from "../AccountPopover/AccountPopOver";
import styles from "./styles.module.css";
import { localStorageInstance } from "../../utils/localStorage";
import ConfirmModal from "../ConfirmationModal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import OnBoard from "../OnBoard/OnBoard";
import { isAdmin } from "../../utils/helpers";


export default function Navbar() {
  const user = localStorageInstance.getItem("user");
  const [open, setOpen] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogOut = () => {
    localStorageInstance.clear();
    queryClient.clear();
    navigate("/");
  };

  const handleopen = (event) => {
    setOpen(true);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#BDCCDC1F", boxShadow: 0 }}
      >
        <Toolbar>
          <Box className={styles.logoCont}>
            <img className={styles.logo} src={"/sofmen-logo.svg"} alt="logo" />
          </Box>

          <Stack
            direction="row"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <Box
              onClick={(event) => handleopen(event)}
              sx={{ cursor: "pointer" }}
            >
              <Default name={user?.name} designation={user?.position} />
            </Box>
          </Stack>

          <AccountPopOver
            openpop={open}
            handleProfile={() => setProfileModal(true)}
            handleClose={(value) => setOpen(false)}
            handleLogout={() => setLogoutModal(true)}
            anchorEl={anchorEl}
          />
        </Toolbar>
      </AppBar>
      <OnBoard
        openProp={profileModal}
        handleClose={() => setProfileModal(false)}
        currentEmployeeId={user?.employee_id}
        title={isAdmin() ? "Update Profile" : "View Profile"}
      />
      {logoutModal && (
        <ConfirmModal
          openProp={logoutModal}
          handleClose={() => setLogoutModal(false)}
          handleConfirm={handleLogOut}
          tabHeader="Logout"
          heading="Are You Logging Out?"
          content="Are you sure you want to Logout ?"
          btnText="log out"
        />
      )}
    </Box>
  );
}
