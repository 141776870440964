import { useQuery } from "@tanstack/react-query";
import { getManualList } from "../../../services/attendance/apiInOutEntries";

export const ManualListQuery = ({ date }) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["manualList", date],
    queryFn: () => getManualList(date),
  });
  const list = data?.data;

  // console.log("object === ", list);
  return { list, isLoading, isError, refetch };
};
