import Api from "../axios";

export default async function apiAttendanceRegister(
  employeeId,
  startDate,
  endDate
) {
  return await Api.get(
    `/attendance_report?employee_id=${employeeId}&start_date=${startDate}&end_date=${endDate}`
  );
}
