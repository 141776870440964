import Dashboard from "../../pages/Dashboard/Dashboard";
import ManageTeam from "../../pages/ManageTeam/MangeTeam";
import Private, { ForbidUserAccess, ForbidManagerUserAccess } from "./private";
import ErrorFallback from "../../components/ErrorFallback";
import LeaveStatementPdfPreview from "../../components/PDF/LeaveStatementPdfPreview";
import TeamDashboard from "../../pages/ManageTeam/TeamDashboard/TeamDashboard";
import CompanyStuff from "../../pages/ManageTeam/CompanyStuff/CompanyStuff";
import Reports from "../../pages/ManageTeam/Reports/Reports";
import LeaveStatementReport from "../../pages/ManageTeam/Reports/LeaveStatementReport";
import AttendanceRegisterReport from "../../pages/ManageTeam/Reports/AttendanceRegisterReport";
import SummaryReport from "../../pages/ManageTeam/Reports/SummaryReport";
import SummaryReportPdfPreview from "../../components/PDF/SummaryReportPdfPreview";
import RunJob from "../../components/RunJob/RunJob";
import ManageEmployee from "../../pages/ManageTeam/ManageEmployee";
import NotFoundView from "../../pages/NotFound";

export const Pages = {
  element: <Private />,
  children: [
    {
      element: <Dashboard />,
      path: "/dashboard",
    },
    {
      element: (
        <ForbidUserAccess>
          <ManageTeam />
        </ForbidUserAccess>
      ),
      path: "/manage",
      children: [
        {
          path: "",
          element: <TeamDashboard />,
        },
        {
          path: "company-stuff",
          element: (
            <ForbidManagerUserAccess>
              <CompanyStuff />
            </ForbidManagerUserAccess>
          ),
        },
        {
          path: "reports",
          element: (
            <ForbidManagerUserAccess>
              <Reports />
            </ForbidManagerUserAccess>
          ),
          children: [
            {
              path: "leave-statement",
              element: <LeaveStatementReport />,
            },
            {
              path: "attendance-register",
              element: <AttendanceRegisterReport />,
            },
            {
              path: "summary-report",
              element: <SummaryReport />,
            },
          ],
        },
      ],
    },
    {
      element: (
        <ForbidManagerUserAccess>
          <LeaveStatementPdfPreview />
        </ForbidManagerUserAccess>
      ),
      path: "/leave-statement-preview",
    },
    {
      element: (
        <ForbidManagerUserAccess>
          <SummaryReportPdfPreview />
        </ForbidManagerUserAccess>
      ),
      path: "/summary-report-preview",
    },
    {
      element: (
        <ForbidManagerUserAccess>
          <RunJob />
        </ForbidManagerUserAccess>
      ),
      path: "/run-job",
    },
    {
      element: (
        <ForbidManagerUserAccess>
          <ManageEmployee />
        </ForbidManagerUserAccess>
      ),
      path: "/employee/:employeeId",
    },
    {
      element: <NotFoundView />,
      path: "*",
    },
  ],
  errorElement: <ErrorFallback />,
};
