import Box from "@mui/material/Box";
import LeaveStatement from "../../Dashboard/LeaveManagement/LeaveStatement/LeaveStatement";
import TableHeader from "../../../components/TableHeader/TableHeader";
import styles from "./styles.module.css";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";

export default function LeaveStatementReport() {
  const { currentEmployee, emplist, onCurrentEmployeeChange } =
    useEmployeeSelection();

  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="Leave Statement"
        emplist={emplist}
        handleChange={onCurrentEmployeeChange}
        currentEmployee={currentEmployee.employee_id}
      />
      <LeaveStatement
        employeeId={currentEmployee.employee_id}
        showButton={true}
        updateColumnWidth={true}
      />
    </Box>
  );
}
