import toast from "react-hot-toast";
import RunJobQuery from "../../utils/reactQuery/RunJob";
import { Box, Typography } from "@mui/material";

export default function RunJob() {
  const { message } = RunJobQuery();
  // console.log("RunJob", message);
  toast.success(message);
  return (
    <Box display="flex" justifyContent="center" alignItems="center" margin={20}>
      <Typography>
        {message ? message : `Job started running. Please wait for some time.`}
      </Typography>
    </Box>
  );
}
