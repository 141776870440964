import {
  Autocomplete,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import Loader from "../../../components/Loader";
import { useEffect, useState } from "react";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import Iconify from "../../../components/Icon/Iconify";
import { RHFMultiCheckbox } from "../../../components/RFH/RFHCheckbox";
import { FormProvider, useForm } from "react-hook-form";
import DropdownPopover from "../../../components/DropDownPopover/DropDownPopover";
import AllEmployeeReportQuery from "../../../utils/reactQuery/manager/employeeDetail/AllEmployeeReport";
import { useResponsive } from "../../../hooks/customUseResponsive";
import ManagersListQuery from "../../../utils/reactQuery/ManagersList";
import { DateRange } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { formatDate, humanizeDateDifference } from "../../../utils/helpers";
import { RHFSelect } from "../../../components/RFH/RFHselect";
import { NavLink } from "react-router-dom";

const teamOptions = [
  { value: "UI", label: "UI" },
  { value: "IOS", label: "IOS" },
  { value: "Android", label: "Android" },
  { value: "PHP", label: "PHP" },
  { value: ".NET", label: ".NET" },
  { value: "Python", label: "Python" },
  { value: "Other", label: "Other" },
];

const employeeStatusOptions = [
  { value: "all", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const breakOptions = [
  { value: "", label: "Select" },
  { value: "5", label: ">5" },
];

const avgWorkHoursOptions = [
  { value: "all", label: "Select" },
  { value: "8.5", label: "8.5" },
  { value: "9", label: "9" },
  { value: "9.5", label: "9.5" },
  { value: "10", label: "10" },
  { value: "10.5", label: "10.5" },
  { value: "11", label: "11" },
  { value: "11.5", label: "11.5" },
  { value: "12", label: "12" },
];

// console.log("avgWorkHoursOptions", avgWorkHoursOptions);

// Custom component for the "Last 30 days" header group
function Last30DaysHeader({
  selectedStartDates,
  selectedEndDates,
  setSelectedStartDates,
  setSelectedEndDates,
}) {
  const d1 = new Date();
  const d2 = new Date();
  d2.setDate(d2.getDate() - 30);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: d2,
      endDate: d1,
      key: "selection",
    },
  ]);
  const [headerText, setHeaderText] = useState("Last 30 days");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedStartDates(formatDate(state[0].startDate));
    setSelectedEndDates(formatDate(state[0].endDate));
    setHeaderText(humanizeDateDifference(state[0].startDate, state[0].endDate));
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box>
        <Typography variant="body2" fontWeight="bold">
          {headerText}
        </Typography>
        {selectedStartDates && selectedEndDates && (
          <Typography>
            {selectedStartDates} to {selectedEndDates}
          </Typography>
        )}
      </Box>
      <IconButton onClick={handleOpen} size="small">
        <CalendarMonthIcon />
      </IconButton>

      {open && (
        <Menu
          id="lock-menu"
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "center" }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </Menu>
      )}
    </Box>
  );
}

export default function AllEmployees() {
  const isMobile = useResponsive("down", "sm");
  const { emplist } = useEmployeeSelection();
  // console.log("AllEmployees emplist", emplist);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedWorkHours, setSelectedWorkHours] = useState("");
  const [selectedBreakCount, setSelectedBreakCount] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  // console.log("selectedEmployee", selectedEmployee);
  // console.log("selectedTeam", selectedTeam);
  // console.log("selectedManager", selectedManager);
  // console.log("selectedStatus", selectedStatus);
  // console.log("selectedWorkHours", selectedWorkHours);
  // console.log("selectedBreakCount", selectedBreakCount);
  // console.log("selectedStartDate", selectedStartDate);
  // console.log("selectedEndDate", selectedEndDate);

  const { managersList } = ManagersListQuery();
  // console.log("managersList", managersList);

  const { report, isLoading } = AllEmployeeReportQuery(
    selectedEmployee?.employee_id || "all",
    selectedTeam,
    selectedManager,
    selectedStatus,
    selectedWorkHours,
    selectedStartDate,
    selectedEndDate,
    selectedBreakCount
  );

  const handleChange = (event, newValue) => {
    setSelectedEmployee(newValue);
  };

  const defaultValues = {
    team: [],
    manager: [],
    status: "Active",
    workingHours: "all",
  };

  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;

  // Watch specific fields for changes
  const teamValue = watch("team");
  const managerValue = watch("manager");
  const statusValue = watch("status");
  const workingHoursValue = watch("workingHours");

  // Sync form values with state
  useEffect(() => {
    setSelectedTeam(teamValue || []);
  }, [teamValue]);

  useEffect(() => {
    setSelectedManager(managerValue || []);
  }, [managerValue]);

  useEffect(() => {
    setSelectedStatus(statusValue || "");
  }, [statusValue]);

  useEffect(() => {
    // timeExtractor(workingHoursValue);
    setSelectedWorkHours(workingHoursValue || "");
  }, [workingHoursValue]);

  const columns = [
    {
      field: "col1",
      headerName: "",
      sortable: false,
      resizable: false,
      renderCell: (value) => (
        <NavLink
          to={`/employee/${value.value.employee_id}`}
          target="_blank"
          className={styles.link}
        >
          {value.value.name}
        </NavLink>
      ),
      minWidth: isMobile ? 70 : 200,
    },
    {
      field: "col2",
      headerName: "",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      minWidth: isMobile ? 70 : 200,
    },
    {
      field: "col3",
      headerName: "",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      minWidth: isMobile ? 70 : 156,
    },
    {
      field: "col4",
      headerName: "P",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col5",
      headerName: "A",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col6",
      headerName: "R",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col7",
      headerName: "LWP",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col8",
      headerName: "In/Out",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col9",
      headerName: "WFH",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col10",
      headerName: "",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "spacing",
    },
    {
      field: "col11",
      headerName: "Short Days",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "spacing",
    },
    {
      field: "col12",
      headerName: "Breaks",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
      renderHeader: () => (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <span>Breaks</span>
          <select
            style={{
              marginTop: 4,
              width: "70%",
              fontSize: "12px",
            }}
            onChange={(event) => {
              // console.log("Selected:", event.target.value);
              setSelectedBreakCount(event.target.value);
            }}
            value={selectedBreakCount}
          >
            {breakOptions.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </Box>
      ),
    },
    {
      field: "col13",
      headerName: "Avg. Hours",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "spacing",
    },
    {
      field: "col14",
      headerName: "Late",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "rightPadding",
    },
    {
      field: "col15",
      headerName: "Leave Taken",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 79,
      headerClassName: "spacing",
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Employee Info",
      headerName: "Employee Info",
      children: [{ field: "col1" }],
    },
    {
      groupId: "Management",
      headerName: "Manager",
      children: [{ field: "col2" }],
    },
    {
      groupId: "Team Info",
      headerName: "Team",
      children: [{ field: "col3" }],
    },
    {
      groupId: "Upcoming Leaves",
      description: "",
      children: [
        { field: "col4" },
        { field: "col5" },
        { field: "col6" },
        { field: "col7" },
      ],
      headerClassName:
        "MuiDataGrid-columnHeader--filledGroup MuiDataGrid-columnHeaderTitleContainer",
    },
    {
      groupId: "Pending Attendance",
      description: "",
      children: [{ field: "col8" }, { field: "col9" }],
      headerClassName:
        "MuiDataGrid-columnHeader--filledGroup MuiDataGrid-columnHeaderTitleContainer",
    },
    {
      groupId: "Comp Off",
      children: [{ field: "col10" }],
    },
    {
      groupId: "Last 30 days",
      headerName: "Last 30 days",
      description: "",
      children: [
        { field: "col11" },
        { field: "col12" },
        { field: "col13" },
        { field: "col14" },
        { field: "col15" },
      ],
      headerClassName:
        "MuiDataGrid-columnHeader--filledGroup MuiDataGrid-columnHeaderTitleContainer",
      renderHeaderGroup: () => (
        <Last30DaysHeader
          selectedStartDates={selectedStartDate}
          selectedEndDates={selectedEndDate}
          setSelectedStartDates={setSelectedStartDate}
          setSelectedEndDates={setSelectedEndDate}
        />
      ),
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={4}
            paddingY={2}
          >
            <Box width={350}>
              <Autocomplete
                options={emplist}
                getOptionLabel={(option) => option.name} // Define how the options should be displayed
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Iconify
                          icon="ph:magnifying-glass-bold"
                          color="#B9B9B9"
                        />
                      ),
                    }}
                  />
                )}
                value={selectedEmployee}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.employee_id
                }
                fullWidth
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <DropdownPopover buttonLabel="Team">
                <RHFMultiCheckbox name="team" options={teamOptions || []} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Manager">
                <RHFMultiCheckbox name="manager" options={managersList || []} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Status">
                {/* <RHFMultiCheckbox
                  name="status"
                  options={employeeStatusOptions || []}
                /> */}
                <RHFSelect name="status">
                  {employeeStatusOptions.map((opt, idx) => (
                    <MenuItem value={opt.value} key={idx}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </DropdownPopover>
              <DropdownPopover buttonLabel="Avg. Working Hours" isTimer={true}>
                {/* <RHFTimePicker name="workingHours" showAmPm={false} /> */}
                <RHFSelect name="workingHours">
                  {avgWorkHoursOptions.map((opt, idx) => (
                    <MenuItem key={idx} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </DropdownPopover>
            </Box>
          </Box>
        </form>
      </FormProvider>

      <Box
        className={styles.dataGridContainer}
        sx={{
          "& .spacing": {
            paddingLeft: "16px !important",
          },
          "& .rightPadding": {
            paddingRight: "8px !important",
          },
          "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
            {
              borderBottom: "0 !important",
            },
        }}
      >
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <Loader />
          </Stack>
        )}
        <DataGridComp
          columns={columns || []}
          initialRows={report || []}
          columnGroupingModel={columnGroupingModel || []}
          footer={false}
          pageSize={50}
        />
      </Box>
    </>
  );
}
