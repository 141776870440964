import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../Profile/styles.module.css";
import UploadIcon from "../../styles/icons/uploadIcon.svg";
import { InputLabel } from "@mui/material";

const fileTypeMapping = {
  "application/pdf": "PDF",
  "application/msword": "DOC",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "DOCX",
  "application/vnd.ms-excel": "XLS",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "application/zip": "ZIP",
  "application/vnd.oasis.opendocument.text": "ODT",
  "application/vnd.oasis.opendocument.spreadsheet": "ODS",
};

const extensionMapping = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  odt: "application/vnd.oasis.opendocument.text",
  ods: "application/vnd.oasis.opendocument.spreadsheet",
  zip: "application/zip",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  webp: "image/webp",
  heic: "image/heic",
  // bmp: "image/bmp",
  // tif: "image/tiff",
  // tiff: "image/tiff",
  // svg: "image/svg+xml",
};
// Check file extension and type
const isValidFile = (file, accept) => {
  const fileExtension = file.name.split(".").pop().toLowerCase();
  const isAcceptedByType = accept.includes(file.type);
  // console.log("isAcceptedByType", isAcceptedByType);
  const isAcceptedByExtension =
    Object.keys(extensionMapping).includes(fileExtension);
  // console.log("isAcceptedByExtension", isAcceptedByExtension);

  return isAcceptedByType || isAcceptedByExtension;
};

const renderFilePreview = (file) => {
  if (typeof file === "string") {
    // For URL strings
    // const extension =
    //   fileTypeMapping[extensionMapping[file.split(".").pop().toLowerCase()]];
    const extension = file.split(".").pop().toUpperCase();
    // console.log("extension", extension);

    return Object.keys(extensionMapping).some((ext) =>
      file.includes(`.${ext}`)
    ) ? (
      <Box
        width={50}
        height={50}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid #ddd"
        borderRadius="4px"
        marginRight="10px"
      >
        <Typography variant="caption">{extension}</Typography>
      </Box>
    ) : (
      <Box
        component="img"
        src={file}
        alt="Preview"
        width={50}
        height={50}
        marginRight={1}
        sx={{ objectFit: "cover" }}
      />
    );
  }
  if (file?.type?.startsWith("image/")) {
    return (
      <Box
        component="img"
        src={URL.createObjectURL(file)}
        alt="Preview"
        width={50}
        height={50}
        marginRight={1}
        sx={{ objectFit: "cover" }}
      />
    );
  } else {
    const fileType = fileTypeMapping[file.type] || "FILE";
    return (
      <Box
        width={50}
        height={50}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid #ddd"
        borderRadius="4px"
        marginRight="10px"
      >
        <Typography variant="caption">{fileType}</Typography>
      </Box>
    );
  }
};

export default function RHFFileUpload({
  name,
  multiple = false,
  // label,
  accept,
}) {
  const { control, getValues } = useFormContext();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]); // Track rejected files

  useEffect(() => {
    const existingFiles = getValues(name);
    if (existingFiles) {
      setSelectedFiles(multiple ? existingFiles : [existingFiles]);
    }
  }, [getValues, name, multiple]);

  const handleFileChange = (event, field) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to array
    const validFiles = [];
    const invalidFiles = [];

    // Validate files by type and extension
    newFiles.forEach((file) => {
      if (isValidFile(file, accept)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    const updatedFiles = multiple
      ? [...selectedFiles, ...validFiles].filter(
          (file, index, self) =>
            index === self.findIndex((f) => f.name === file.name)
        )
      : validFiles; // Filter duplicates by name

    setSelectedFiles(updatedFiles); // Update valid files
    setRejectedFiles(invalidFiles); // Track invalid files
    field.onChange(updatedFiles); // Pass valid files to react-hook-form

    // Clear input for new selections
    event.target.value = null;
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
  };

  const handleRemoveRejectedFile = (fileToRemove) => {
    setRejectedFiles(rejectedFiles.filter((file) => file !== fileToRemove));
  };

  // console.log("selectedFiles", selectedFiles);
  // console.log("rejectedFiles", rejectedFiles);

  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={multiple ? [] : null} // For multiple: empty array; for single: null
      render={({ field, fieldState: { error } }) => (
        <Box>
          {/* <Typography variant="body1" gutterBottom>
            {label}
          </Typography> */}
          <Button
            variant="contained"
            component="label"
            className={styles.btn}
            sx={{ mb: 2 }}
          >
            {multiple ? "Upload Files" : "Upload File"}
            <input
              type="file"
              accept={accept}
              hidden
              multiple={multiple}
              onChange={(e) => handleFileChange(e, field)}
            />
          </Button>

          {/* Accepted Files */}
          <Box marginLeft={2}>
            {selectedFiles.length > 0 && (
              <List>
                {selectedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>{renderFilePreview(file)}</ListItemIcon>
                    <ListItemText primary={file.name} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(file)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>

          {/* Rejected Files */}
          {rejectedFiles.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6" color="error">
                Rejected Files (Invalid Type):
              </Typography>
              <Typography variant="body2" color="error">
                {rejectedFiles[0]?.name?.split(".")?.pop()} files not allowed
              </Typography>
              <List>
                {rejectedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveRejectedFile(file)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {/* Error Message */}
          {error && (
            <Typography variant="body2" color="error">
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}

export function RHFPolicyFileUploader({
  name,
  label,
  accept = "image/jpeg,image/png",
  helperText,
  placeholder,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} {...other}>
          {label && <InputLabel shrink>{label}</InputLabel>}
          <Box>
            <input
              type="file"
              accept={accept}
              onChange={(e) => field.onChange(e.target.files[0])}
              style={{ display: "block", marginTop: 8, marginBottom: 4, width: 290 }}
            />
            {!field.value && placeholder && (
              <Box sx={{ color: "text.disabled", marginTop: 1 }}>
                {placeholder}
              </Box>
            )}
          </Box>

          {(!!error || helperText) && (
            <FormHelperText>
              {error ? error.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}


export function RHFFileUploader({
  name,
  label,
  accept = "image/jpeg,image/png",
  helperText,
  placeholder = "Upload Image",
  previewUrl,
  disabled,
  ...other
}) {
  const { control } = useFormContext();
  // console.log("previewUrl", previewUrl);
  const [preview, setPreview] = useState(previewUrl);

  const handleFileChange = (event, field) => {
    const file = event.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      field.onChange(file);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error}>
          {label && <Typography variant="subtitle1">{label}</Typography>}
          <Box
            sx={{
              border: "1px dashed #c4c4c4",
              borderRadius: "8px",
              padding: "16px",
              textAlign: "center",
              cursor: disabled ? "default" : "pointer",
              color: "text.secondary",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "auto",
              width: "300px",
              position: "relative",
              overflow: "hidden",
              "&:hover": {
                borderColor: disabled ? "disabled" : "primary.main",
              },
            }}
          >
            <input
              type="file"
              accept={accept}
              onChange={(e) => handleFileChange(e, field)}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: disabled ? "default" : "pointer",
              }}
              disabled={disabled}
              {...other}
            />
            {preview ? (
              <Box
                component="img"
                src={preview}
                alt="Uploaded Preview"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <>
                {/* <UploadIcon sx={{ fontSize: 48, color: "primary.main" }} /> */}
                <Box component="img" src={UploadIcon} alt="uploadImageIcon" />
                <Typography
                  variant="body2"
                  fontSize={16}
                  fontWeight={600}
                  color="#212B36"
                >
                  {placeholder}
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={14}
                  fontWeight={400}
                  color="#637381"
                >
                  Support JPEG, PNG files.
                </Typography>
              </>
            )}
          </Box>

          {(!!error || helperText) && (
            <FormHelperText>
              {error ? error.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
