import { useQuery } from "@tanstack/react-query";
import { getInOutMissingEntry } from "../../../../services/manager/count/apiInoutMissingEntry";
// import { getMissingEntryCount } from "../../../../services/manager/count/apiMIssingEntryCount";

export const InOutMissingEntry = ({ id, date }) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["InOutMissingEntry", id, date],
    queryFn: () => getInOutMissingEntry({ id, date }),
  });

  const list = data?.data;

  return { list, isLoading, refetch };
};
