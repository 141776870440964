import { useNavigate, useRouteError } from "react-router-dom";

function ErrorFallback({ error, resetErrorBoundary }) {
  const err = useRouteError();
  const navigate = useNavigate();

  console.error("err", err);
  console.error("emessage", err.message);
  console.error("estack", err.stack);

  function resetError() {
    navigate(0);
  }
  return (
    <div className="errorPage">
      <h1>Oops! Something went wrong 🧐</h1>
      <button size="large" onClick={resetError}>
        Try again
      </button>
      {/* {err.message && <p>Message: {err.message}</p>}
        {err.stack && <pre>{err.stack}</pre>}
        {err.status && <p>Status: {err.status}</p>}
        {err.statusText && <p>Status Text: {err.statusText}</p>}
        {err.data && <pre>{JSON.stringify(err.data, null, 2)}</pre>} */}
    </div>
  );
}

export default ErrorFallback;
