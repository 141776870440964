import { useQuery } from "@tanstack/react-query";
import { getLateComing } from "../../services/count/apiLateComing";

export function LateComingQuery(employeeId) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["lateComing", employeeId],
    queryFn: () => getLateComing(employeeId),
  });

  let lateComing = data?.data.total_late_check_ins;

  return { isLoading, isError, lateComing };
}