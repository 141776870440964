import Api from "../../axios";

export default async function apiAllEmployeeReport(
  employeeId,
  team,
  manager,
  status,
  workHours,
  startDate,
  endDate,
  breakCount
) {
  return await Api.get(
    `/all_employee_report?employee_id=${employeeId}&manager_id=${manager}&employee_status=${status}&department=${team}&avg_hours=${workHours}&start_date=${startDate}&end_date=${endDate}&break_count=${breakCount}`
  );
}
