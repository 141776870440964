import { useQuery } from "@tanstack/react-query";
import apiManagerEmployees from "../../../services/manager/apiManagerEmployees";

export default function ManagerEmployeesQuery() {
  const { data, isLoading } = useQuery({
    queryKey: ["managerEmployees"],
    queryFn: () => apiManagerEmployees(),
  });

  // console.log("ManagerEmployees data", data?.data);
  const employees = data?.data.employees.map((employee, idx) => ({
    id: idx,
    col1: employee.name,
    col2: employee.joining_date,
    col3: employee.position,
    col4: employee.mobile_number,
  }));

  return { employees, isLoading };
}
