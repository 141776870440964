import * as yup from "yup";

export const addFeedbackSchema = yup.object().shape({
    employee_id: yup.string().required("Please select user."),
    // quarter: yup
    //   .string()
    //   .required("Quarter is required"),
    feedback: yup.string().required('Please enter feedback.'),
    is_publish: yup.boolean()
  });
