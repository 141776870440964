import { useQuery } from "@tanstack/react-query";
import getPreviousMonthLeave from "../../services/count/apiPreviousMonthLeaves";

export function PreviousMonthLeavesQuery(employeeId) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["previousMonthLeaves", employeeId],
    queryFn: () => getPreviousMonthLeave(employeeId),
  });

  const previousMonthLeaves = data?.data.total_leave;

  return { previousMonthLeaves, isLoading, isError };
}