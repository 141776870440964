import { useQuery } from "@tanstack/react-query";
import { getTotalHoursMissingEntry } from "../../../../services/manager/count/apiTotalHourMissingEntry";

export const TotalHoursMissingEntry = ({ id, date }) => {
  const { data, refetch } = useQuery({
    queryKey: ["totalHoursMissingEntry", id, date],
    queryFn: () => getTotalHoursMissingEntry({ id, date }),
  });

  const totalHours = data?.data[0]?.total_hours_spent;

  return { totalHours, refetch };
};
