import { useQuery } from "@tanstack/react-query"

import { getMissingStatusList } from "../../../../services/manager/count/apiMissingStatus"
import { usDateFormate } from "../../../../hooks/dateFormat"

export const MissingStatusQuery=({startDate, endDate})=>{
    const {data, isLoading} = useQuery({
        queryKey: ['missingStatus',startDate,endDate],
        queryFn: ()=>getMissingStatusList({startDate: startDate, endDate: endDate})
    })

    // console.log("MissingStatusQuery data", data?.data.missing_report);

    let missingStatus = data?.data.missing_report.map((ele, idx) => {
      return {
        id: idx,
        col1: ele.username,
        col2: usDateFormate(ele.date.slice(0, 10)),
        col3: ele.total_hours_spent,
        col4: ele.delay,
      };
    });

    return {missingStatus, isLoading}
}