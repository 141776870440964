import styles from "./styles.module.css";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { leaveLogSchema as attendanceRegisterSchema } from "../../schemas/leaveLog";
import DatePickerComponent from "../DatePickerComp/DatePickerComp";
import AttendanceRegisterQuery from "../../utils/reactQuery/attendance/AttendanceRegister";
import Loader from "../Loader";
import downloadIcon from "../../styles/icons/downloadIcon.svg";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

const DownloadButtonIcon = (
  <Box
    component="img"
    src={downloadIcon}
    alt="downloadIcon"
    height={28}
    width={28}
  />
);

const CustomNoRowsOverlay = () => {
  return (
    <Stack className={styles.dataNotFound}>
      <Typography variant="subtitle1">No data found</Typography>
    </Stack>
  );
};

const downloadExcel = (rows, updatedColumns, setPreviewPdf) => {
  if (!rows || !updatedColumns) return;

  setPreviewPdf(true);
  // 1. Prepare data for the Excel sheet
  const excelData = rows.map((row) => {
    const rowData = {};
    updatedColumns.forEach((col) => {
      rowData[col.headerName] = row[col.field] || ""; // Add each field to the row data
    });
    return rowData;
  });

  // 2. Create a new workbook and a worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(excelData);

  // 3. Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance Register");

  // 4. Generate Excel file and trigger a download
  XLSX.writeFile(workbook, "Attendance_Register.xlsx");

  setPreviewPdf(false);
};

export default function AttendanceRegister({ currentEmployeeId }) {
  const date = new Date();
  const [isGenerating, setIsGenerating] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);

  // Calculate start and end dates
  const startDate = useRef(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 5)
      .toISOString()
      .split("T")[0] // Extract just the date part in "YYYY-MM-DD" format
  );
  const endDate = useRef(date.toISOString().split("T")[0]);

  // console.log("startDate:", startDate.current);
  // console.log("endDate:", endDate.current);

  const { updatedColumns, rows, isLoading } = AttendanceRegisterQuery(
    currentEmployeeId,
    startDate.current,
    endDate.current
  );

  const methods = useForm({
    resolver: yupResolver(attendanceRegisterSchema),
  });

  useEffect(() => {
    methods.reset({
      startDate: dayjs(startDate.current),
      endDate: dayjs(endDate.current),
    });
  }, [methods]);

  const onSubmit = async (formData) => {
    setIsGenerating(true);

    const start_date = `${formData.startDate.getFullYear()}-${
      formData.startDate.getMonth() + 1
    }-${formData.startDate.getDate()}`;

    const end_date = `${formData.endDate.getFullYear()}-${
      formData.endDate.getMonth() + 1
    }-${formData.endDate.getDate()}`;

    startDate.current = start_date;
    endDate.current = end_date;

    // refetch();

    setIsGenerating(false);
  };

  return (
    <>
      <Stack direction="row" className={styles.container}>
        <Stack direction="row" className={styles.shortFormCont}>
          <Stack direction="row" className={styles.subCont}>
            <Box className={[styles.indicator, styles.presentIndicator]} />
            <Typography className={styles.shortFormText}>
              <b>P</b>&nbsp;-&nbsp;Present
            </Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={[styles.indicator, styles.holidayIndicator]} />
            <Typography className={styles.shortFormText}>
              <b>H</b>&nbsp;-&nbsp;Holiday
            </Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={[styles.indicator, styles.weekendIndicator]} />
            <Typography className={styles.shortFormText}>
              <b>W</b>&nbsp;-&nbsp;Weekend
            </Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={[styles.indicator, styles.absentIndicator]} />
            <Typography className={styles.shortFormText}>
              <b>A</b>&nbsp;-&nbsp;Absent
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box paddingBottom={1} minHeight={250}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.formContainer}>
              <Stack className={styles.dateSection}>
                <Typography className={styles.datePickerlabel}>
                  From Date
                </Typography>

                <DatePickerComponent name="startDate" />
              </Stack>

              <Stack className={styles.dateSection}>
                <Typography className={styles.datePickerlabel}>
                  To Date
                </Typography>
                <DatePickerComponent name="endDate" />
              </Stack>

              <LoadingButton
                loading={isGenerating}
                type="submit"
                variant="contained"
                className={styles.generateBtn}
              >
                Generate Report
              </LoadingButton>

              <LoadingButton
                variant="contained"
                startIcon={DownloadButtonIcon}
                className={styles.downloadButton}
                loading={previewPdf}
                onClick={() =>
                  downloadExcel(rows, updatedColumns, setPreviewPdf)
                }
              >
                Download Spreadsheet
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
        <Box className={styles.dataGridContainer}>
          {isLoading && (
            <Stack className={styles.loaderCont}>
              <Loader />
            </Stack>
          )}
          {updatedColumns && rows && (
            <DataGrid
              columns={updatedColumns || []}
              rows={rows || []}
              disableColumnMenu
              getRowClassName={(params) =>
                params.row.disabled
                  ? "MuiDataGrid-row disabled"
                  : "MuiDataGrid-row disabled"
              }
              initialState={{
                pagination: { paginationModel: { pageSize: 99 } },
              }}
              showCellVerticalBorder={true}
              showColumnVerticalBorder={true}
              autoHeight={true}
              hideFooter={true}
              rowSelection={false}
              slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              sx={{
                border: 0,
                "& .MuiDataGrid-main": {
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                },
                "& .MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "& .MuiDataGrid-cell": {
                  fontSize: "14px !important",
                  fontWeight: "400 !important",
                  color: "#455F69",
                },
                "& .MuiDataGrid-columnHeader": {
                  height: "48px !important",
                  backgroundColor: "#F5F8FC",
                  color: "#455F69",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                  borderBottom: "1px solid #CED4DA",
                },
                "& .MuiDataGrid-footerContainer": {
                  border: "none",
                },
                "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
                  backgroundColor: "transparent",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "transparent",
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
                "& .late-coming-cell": {
                  backgroundColor: "mistyrose",
                },
                "& .calculated-total-time-cell": {
                  backgroundColor: "lightgray",
                },
                ".presentText": {
                  color: "#455F69",
                },

                ".holidayText": {
                  color: "#FE9D35",
                },

                ".weekendText": {
                  color: "#34AFF7",
                },

                ".absentText": {
                  color: "#E92C2C",
                },
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
