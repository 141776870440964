import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  frontPage: {
    top: "30%",
    left: "30%",
  },
  textBold: {
    fontSize: 28,
    fontWeight: "bold",
  },
  textMargin: {
    marginBottom: 10,
  },
  page: {
    padding: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f0f0f0",
    textAlign: "center",
  },
  tableCol: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 8,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
  title: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
  },
  dates: {
    marginBottom: 20,
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
  },
});

// Helper function to split data into chunks of specified size
const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array?.length; i += size) {
    chunks.push(array?.slice(i, i + size));
  }
  return chunks;
};

export default function SummaryReportPdf({ pdfData, fromDate, toDate }) {
  // Split pdfData into chunks of 10 items each
  const dataChunks = chunkArray(pdfData, 10);

  return (
    <Document>
      {dataChunks.map((chunk, index) => (
        <Page key={index} size="A4" style={styles.page}>
          {index === 0 && (
            <View style={styles.dates}>
              <Text>From Date: {fromDate}</Text>
              <Text>To Date: {toDate}</Text>
            </View>
          )}
          {chunk.map((data) => (
            <View key={data.id} style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Employee Name</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Month & Year</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Total Days</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Working Days</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Present Days</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Off Days</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Casual</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Medical</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Weekend Working</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Avg. Hrs/Days</Text>
                </View>
              </View>

              {/* Table Row Data */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col2}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col3}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col4}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col5}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col6}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col7}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col8}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col9}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.col10}</Text>
                </View>
              </View>
            </View>
          ))}
        </Page>
      ))}
    </Document>
  );
}
