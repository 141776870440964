import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Iconify from "../Icon/Iconify";

export default function TableHeader({
  heading,
  btntxt,
  btnicon,
  handleModal,
  emplist,
  currentEmployee,
  handleChange,
}) {
  return (
    <Box className={styles.container}>
      <Box className={styles.greenTab} />
      <Stack direction="row" className={styles.headerContainer}>
        <Typography className={styles.tableHead}>{heading}</Typography>
        {btntxt && (
          <Button
            className={styles.btnClass}
            onClick={() => handleModal()}
            variant="contained"
            color="primary"
          >
            {btntxt}
          </Button>
        )}
        {btnicon && (
          <Button
            className={[styles.btnClass, styles.iconBtn]}
            onClick={() => handleModal()}
            variant="contained"
            color="primary"
          >
            <Iconify icon={btnicon} />
          </Button>
        )}
        {emplist && (
          <Select
            className={styles.dropdown}
            value={currentEmployee}
            onChange={handleChange}
          >
            {emplist?.map((employee) => (
              <MenuItem value={employee.employee_id} key={employee.employee_id}>
                {employee.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>
    </Box>
  );
}
