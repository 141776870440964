import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Menu,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { useResponsive } from "../../../hooks/customUseResponsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EmployeeUnderManagerQuery } from "../../../utils/reactQuery/manager/EmployeeUnderManager";
import { ShortDayListQuery } from "../../../utils/reactQuery/manager/ShortDayList";
import { usDateFormate } from "../../../hooks/dateFormat";
import calenderIcon from "../../../styles/icons/calenderIcon.svg";
import { DateRange } from "react-date-range";

export default function ShortHoursList({ openProp, handleClose }) {
  const isMobile = useResponsive("down", "sm");
  const [searchLetter, setSearchLetter] = useState("");
  const { employeeList } = EmployeeUnderManagerQuery();
  // const [listOfEmployee, setListOfEmployee] = useState([]);
  const [dateRange, setDateRange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const d = new Date();

  // Start date: First day of the current month
  const startOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
  const selectedStartDate = useRef(
    `${startOfMonth.getFullYear()}-${String(
      startOfMonth.getMonth() + 1
    ).padStart(2, "0")}-${String(startOfMonth.getDate()).padStart(2, "0")}`
  );

  // End date: Today
  const selectedEndDate = useRef(
    `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(
      d.getDate() - 1
    ).padStart(2, "0")}`
  );

  const [state, setState] = useState([
    {
      startDate: selectedStartDate.current,
      endDate: selectedEndDate.current,
      key: "selection",
    },
  ]);

  const [selectedUser, setSelectedUser] = useState({
    employee_id: 0,
    name: "",
  });

  const [accordExpand, setAccordExpand] = useState(false);

  const { shortDayEmployee, isLoading } = ShortDayListQuery({
    id: selectedUser.employee_id,
    startDate: selectedStartDate.current,
    endDate: selectedEndDate.current,
  });

  const handleClick = (event) => {
    setDateRange(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = () => {
    selectedStartDate.current = `${state[0].startDate.getFullYear()}-${String(
      state[0].startDate.getMonth() + 1
    ).padStart(2, "0")}-${String(state[0].startDate.getDate()).padStart(
      2,
      "0"
    )}`;

    selectedEndDate.current = `${state[0].endDate.getFullYear()}-${String(
      state[0].endDate.getMonth() + 1
    ).padStart(2, "0")}-${String(state[0].endDate.getDate()).padStart(2, "0")}`;

    // refetch()

    setDateRange(false);
  };

  // console.log(
  //   "ShortHoursList selectedStartDate.current",
  //   selectedStartDate.current
  // );
  // console.log(
  //   "ShortHoursList selectedEndDate.current",
  //   selectedEndDate.current
  // );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
  };

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 120,
      flex: 2.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Date",
      minWidth: 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Hours Spent",
      minWidth: 120,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "number",
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];

  const handleClearSelected = () => {
    setSelectedUser({ employee_id: 0, name: "" });
    setAccordExpand((val) => !val);
  };

  const handleClearBtn = () => {
    selectedEndDate.current = " ";
    selectedStartDate.current = " ";
    setDateRange(false);
  };

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <Box className={styles.container}>
          <Box className={styles.greenTab} />
          <Stack direction="row" className={styles.headerContainer}>
            <Typography className={styles.tableHead}>
              Employees Short Hours List
            </Typography>
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>
        </Box>
        <Box className={styles.bodyCont}>
          <Stack className={styles.selectorCont}>
            <Stack className={styles.filterByDateCont}>
              <Typography className={styles.filterDate}>Employee</Typography>

              <Box
                className={styles.accordCont}
                sx={{
                  "& .MuiAccordion-root": {
                    boxShadow: "none",
                    border: "1px solid #CED4DA",
                  },
                }}
              >
                <Accordion
                  expanded={accordExpand}
                  onChange={() => setAccordExpand((val) => !val)}
                  className={styles.accord}
                >
                  <AccordionSummary
                    className={styles.accordSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      "& .MuiAccordionSummary-content .Mui-expanded": {
                        margin: "5px !important",
                      },
                    }}
                  >
                    <span className={styles.testfieldContent}>
                      {selectedUser.name}
                    </span>
                    {selectedUser.name ? (
                      <Button
                        onClick={handleClearSelected}
                        className={styles.clearBtn}
                      >
                        clear
                      </Button>
                    ) : (
                      ""
                    )}
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#fff" }}>
                    <TextField
                      fullWidth
                      placeholder="Search Employee Name"
                      variant="standard"
                      value={searchLetter}
                      onChange={(event) => setSearchLetter(event.target.value)}
                      sx={{
                        "& .MuiInput-root": {
                          paddingLeft: "10px",
                          marginBottom: 1,
                        },
                        "& .MuiInput-root::before": {
                          paddingLeft: "20px !important",
                          borderRadius: "20px !important",
                        },
                        "& .MuiInput-root::after": {
                          borderBottom: 0,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                    <Box className={styles.menuItemCont}>
                      {employeeList?.length >= 1
                        ? employeeList
                            .filter((ele) =>
                              ele?.name
                                .toLowerCase()
                                .includes(searchLetter.toLowerCase())
                            )
                            .map((ele, idx) => {
                              return (
                                <Typography
                                  key={idx}
                                  className={styles.menuItem}
                                  onClick={() => {
                                    setSelectedUser(ele);
                                    setAccordExpand(false);
                                  }}
                                >
                                  {ele.name}
                                </Typography>
                              );
                            })
                        : ""}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
            <Stack className={styles.filterByDateCont}>
              <Typography className={styles.filterDate}>
                Filter by Date:
              </Typography>

              <TextField
                onClick={(event) => handleClick(event)}
                value={
                  selectedStartDate.current != " "
                    ? `${usDateFormate(
                        selectedStartDate.current
                      )}-${usDateFormate(selectedEndDate.current)}`
                    : ""
                }
                sx={{
                  width: "293px",
                  "& .MuiInputBase-input": {
                    padding: "9px", // Adjust padding as needed
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingLeft: "16px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <img src={calenderIcon} alt="claender" />
                    </InputAdornment>
                  ),
                }}
              />
              <Menu
                id="lock-menu1"
                anchorEl={anchorEl}
                open={dateRange}
                onClose={() => setDateRange(false)}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setState([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                <Stack className={styles.btnCont}>
                  <Button onClick={handleSubmit} className={styles.submitBtn}>
                    submit
                  </Button>

                  <Button
                    className={styles.closeBtn}
                    onClick={handleClearBtn}
                    variant="outlined"
                  >
                    clear
                  </Button>

                  <Button
                    className={styles.closeBtn}
                    onClick={() => setDateRange(false)}
                    variant="outlined"
                  >
                    close
                  </Button>
                </Stack>
              </Menu>
            </Stack>
          </Stack>
          <Box className={styles.datagridCont}>
            {isLoading ? (
              <Stack className={styles.loaderCont}>
                <CircularProgress />
              </Stack>
            ) : (
              ""
            )}
            <DataGridComp
              columns={columns}
              initialRows={shortDayEmployee ? shortDayEmployee : []}
              pageSize={7}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
