import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.css";
import Attendence from "./Attendence/Attendence";
import LeaveManagement from "./LeaveManagement/LeaveManagement";
import Notices from "./Notices/Notices";
import Policies from "./Policies/Policies";
// import currentMonthLeaveIcon from "../../styles/icons/present.svg";
// import shortDaysIcon from "../../styles/icons/avgWorkHour.svg";
import totalLeaveIcon from "../../styles/icons/totalLeave.svg";
import lateDaysIcon from "../../styles/icons/lateDays.svg";
import shortDaysIcon from "../../styles/icons/lateComing.svg";
import { LateComingQuery } from "../../utils/reactQuery/LateComing";
import { PresentMonthLeaves } from "../../utils/reactQuery/PresentMonthLeaves";
import { PreviousMonthLeavesQuery } from "../../utils/reactQuery/PreviousMonthLeaves";
import { ShortDaysQuery } from "../../utils/reactQuery/ShortDays";
import Navbar from "../../components/Navbar/Navbar";
import { addZeroToSingleDigits, isUser } from "../../utils/helpers";
import EmployeePerformanceFeedback from "../ManageTeam/EmployeesPerformanceFeedback/EmployeesPerformanceFeedback";
import { localStorageInstance } from "../../utils/localStorage";
// import useEmployeeSelection from "../../hooks/useEmployeeSelection";

export default function Dashboard() {
  // const { currentEmployee } = useEmployeeSelection();
  const user = localStorageInstance.getItem("user");

  const { lateComing } = LateComingQuery(user?.employee_id);
  const { presentMonthLeaves } = PresentMonthLeaves(user?.employee_id);
  const { previousMonthLeaves } = PreviousMonthLeavesQuery(user?.employee_id);
  const { shortDays } = ShortDaysQuery(user?.employee_id);

  return (
    <>
      <Navbar />
      <Box className={styles.mainContainer}>
        <Stack className={styles.container} direction="row">
          <Typography className={styles.heading}>Dashboard</Typography>
        </Stack>

        {isUser() && <EmployeePerformanceFeedback />}

        <Stack direction="row" className={styles.cardWrapper}>
          <Stack className={styles.cardCont} direction="row">
            <img
              className={styles.icons}
              src={totalLeaveIcon}
              alt="CurrentMonthLeave"
            />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>
                Leave this month
              </Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(presentMonthLeaves)}
              </Typography>
            </Box>
          </Stack>

          <Stack className={styles.cardCont} direction="row">
            <img
              className={styles.icons}
              src={totalLeaveIcon}
              alt="LastMonthLeave"
            />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>
                Leave last month
              </Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(previousMonthLeaves)}
              </Typography>
            </Box>
          </Stack>

          <Stack className={styles.cardCont} direction="row">
            <img className={styles.icons} src={lateDaysIcon} alt="LateDays" />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>Late days</Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(lateComing)}
              </Typography>
            </Box>
          </Stack>

          <Stack className={styles.cardCont} direction="row">
            <img className={styles.icons} src={shortDaysIcon} alt="ShortDays" />
            <Box className={styles.textCont}>
              <Typography className={styles.cardHead}>
                No. of short days
              </Typography>
              <Typography className={styles.cardContent}>
                {addZeroToSingleDigits(shortDays)}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <Attendence employeeId={user?.employee_id} />

        <LeaveManagement employeeId={user?.employee_id} />

        <Stack direction="row" className={styles.noticePolicieCont}>
          <Box className={styles.sectionCont}>
            <Notices />
          </Box>

          <Box className={styles.sectionCont}>
            <Policies />
          </Box>
        </Stack>
      </Box>
    </>
  );
}
