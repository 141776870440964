import { useQuery } from "@tanstack/react-query"
import { getViewNotice } from "../../../services/notice/apiViewNotice";

export const ViewNotice = ({id})=>{
    const {data, isLoading, isError, refetch} = useQuery({
        queryKey: ['viewNotice',id],
        queryFn: ()=> getViewNotice(id)
    })
    const note = data?.data[0];
    return {note, isLoading, isError, refetch};
}