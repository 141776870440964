import Box from "@mui/material/Box";
import TableHeader from "../../../components/TableHeader/TableHeader";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import styles from "./styles.module.css";
import Summary from "../../../components/SummaryReport/SummaryReport";

export default function SummaryReport() {
  const { currentEmployee, emplist, onCurrentEmployeeChange } =
    useEmployeeSelection();

  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading="Summary Report"
        emplist={emplist}
        handleChange={onCurrentEmployeeChange}
        currentEmployee={currentEmployee.employee_id}
      />
      <Summary currentEmployeeId={currentEmployee.employee_id} />
    </Box>
  );
}
