import { useEffect, useState } from "react";
import { EmployeeUnderManagerQuery } from "../utils/reactQuery/manager/EmployeeUnderManager";
import { localStorageInstance } from "../utils/localStorage";

export default function useEmployeeSelection() {
  const user = localStorageInstance.getItem("user");
  // console.log("user in hook", user);

  const [currentEmployee, setCurrentEmployee] = useState({
    employee_id: "",
    name: "",
  });
  const [emplist, setEmplist] = useState([]);
  const { employeeList } = EmployeeUnderManagerQuery();

  useEffect(() => {
    // Set the employee list to the one retrieved initially
    if (employeeList) {
      // console.log("got existing employees");
      setEmplist(employeeList);
      if (user) {
        // console.log("update the current employee and emplist array");
        // Update the current employee
        setCurrentEmployee({ employee_id: user.employee_id, name: user.name });
        // Update the emplist by adding the new object at the start
        setEmplist((prevList) => [
          { employee_id: 0, name: "Select All" },
          ...prevList,
        ]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList]);

  const onCurrentEmployeeChange = (event) => {
    // console.log("employee change event", event);
    const selectedEmployee = emplist.filter(
      (emp) => emp.employee_id === event.target.value
    )[0].name;
    // console.log("selectedEmployee", event.target.value, selectedEmployee);
    setCurrentEmployee({
      employee_id: event.target.value,
      name: selectedEmployee,
    });
  };
  return {
    currentEmployee,
    emplist,
    onCurrentEmployeeChange,
  };
}
