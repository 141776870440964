import { useQuery } from "@tanstack/react-query";
import getShortDays from "../../services/count/apiShortDays";

export function ShortDaysQuery(employeeId) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["shortDays", employeeId],
    queryFn: () => getShortDays(employeeId),
  });

  const shortDays = data?.data.total_short_days;

  return { shortDays, data, isLoading, isError };
}