import { useQuery } from "@tanstack/react-query";
import { getLeaveLogList } from "../../../services/leave/apiGetLeaveLogList";
import { removeHtmlTagsAndEntities } from "../../../hooks/removehtmlTags";

export function LeaveLogListQuery(employee_id, startDate, endDate) {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["leaveLogList", { employee_id, startDate, endDate }],
    queryFn: () => getLeaveLogList({ employee_id, startDate, endDate }),
  });

  let row = data?.data?.leave_logs.map((ele, idx) => {
    return {
      id: idx,
      col1: { startDate: ele.start_date, endDate: ele.end_date },
      col2: ele.application_type,
      col3: ele.total_days,
      col4: removeHtmlTagsAndEntities(ele.reason ? ele.reason : ""),
      col5: {
        startDate: ele.start_date,
        endDate: ele.end_date,
        approved: ele.is_approved,
      },
    };
  });
  return { row, refetch, isLoading };
}
