import { useQuery } from "@tanstack/react-query";
import apiUserFeedbackList from "../../../../services/manager/EmployeeFeedback/apiUserFeedbackList";

export default function UserFeedbackListQuery() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["userFeedbackList"],
    queryFn: () => apiUserFeedbackList(),
  });

  // console.log("UserFeedbackListQuerydata", data?.data);

  const feedbackList = data?.data.feedback_data?.map((ele, idx) => {
    return {
      id: idx,
      col1: ele.employee_name,
      col2: ele.department,
      col3: ele.date_added,
      col4: ele.feedback_id,
    };
  });

  return { feedbackList, refetch, isLoading };
}
