// import { PDFViewer } from "@react-pdf/renderer";
// import LeaveStatementPdf from "./LeaveStatementPdf";
import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import styles from "./styles.module.css";

export default function LeaveStatementPdfPreview() {
  const [pdfData, setPdfData] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  console.log("LeaveStatement pdfdata", pdfData);

  useEffect(() => {
    // Retrieve the data from localStorage
    const storedPdfData = localStorage.getItem("pdfData");
    const storedCurrentYear = localStorage.getItem("currentYear");

    if (storedPdfData && storedCurrentYear) {
      setPdfData(JSON.parse(storedPdfData));
      setCurrentYear(storedCurrentYear);
    }

    localStorage.removeItem("pdfData");
    localStorage.removeItem("currentYear");
  }, []);

  // console.log("pdfdata preview", currentYear, pdfData);

  // <PDFViewer className="pdfViewer">
  //   <LeaveStatementPdf pdfData={pdfData} currentYear={currentYear} />
  // </PDFViewer>

  return (
    <Box className={styles.previewContainer}>
      <Box className={styles.abbreviationContainer}>
        <Typography variant="h4" gutterBottom>
          Abbreviations
        </Typography>
        <Box className={styles.abbreviations}>
          <Typography variant="h6">CL</Typography>&emsp;
          <Typography variant="h6">Casual Leave</Typography>
        </Box>
        <Box className={styles.abbreviations}>
          <Typography variant="h6">LC</Typography>&emsp;
          <Typography variant="h6">Late Coming</Typography>
        </Box>
        <Box className={styles.abbreviations}>
          <Typography variant="h6">EL</Typography>&emsp;
          <Typography variant="h6">Early Leaving</Typography>
        </Box>
        <Box className={styles.abbreviations}>
          <Typography variant="h6" marginLeft={4}>
            LWP
          </Typography>
          &emsp;
          <Typography variant="h6">Leave without pay</Typography>
        </Box>
      </Box>
      {pdfData?.map((data, index) => (
        <Box key={index} mb={8}>
          <Typography variant="h6" align="center" gutterBottom>
            Leave Statement: {data.name}
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Financial Year: {currentYear}
          </Typography>
          <TableContainer className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={styles.tableCell}>
                    {currentYear}
                  </TableCell>
                  <TableCell align="center" className={styles.tableCell}>
                    Opening Balance
                  </TableCell>
                  <TableCell align="center" className={styles.tableCell}>
                    Allotted
                  </TableCell>
                  <TableCell
                    colSpan={6}
                    align="center"
                    className={styles.tableCell}
                  >
                    Adjustments
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={styles.tableCell}
                  >
                    Closing Balance
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.tableCell}>
                    Month & Year
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    CL
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    CL
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    CL Used
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    LC
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    EL
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    LWP
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    Encashed
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    Comp Off
                  </TableCell>
                  <TableCell align="right" className={styles.tableCell}>
                    CL
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.leaveBalance.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell className={styles.tableCell}>
                      {row.col1}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col2}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col3}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col4}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col5}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col6}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col7}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col8}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col9}
                    </TableCell>
                    <TableCell align="right" className={styles.tableCell}>
                      {row.col10}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
}
