import { useQuery } from "@tanstack/react-query";
import { getTotalHour } from "../../../services/attendance/apiTotalHour";

export default function TotalHourQuery({date}){
    const {data, isLoading: isLoadingTotalHours, refetch} = useQuery({
        queryKey: ['totalHours',date],
        queryFn: ()=> getTotalHour({date})
    })

    let totalHours = data?.data[0].total_hours_spent;

    return {totalHours, isLoadingTotalHours, refetch}
}