import dayjs from "dayjs";
import { localStorageInstance } from "./localStorage";
import relativeTime from "dayjs/plugin/relativeTime";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

export function formatCurrency(value) {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: "EUR",
  }).format(value);
}

export function formatDate(dateStr) {
  // console.log("dateStr", dateStr);
  return new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(dateStr));
}

export function calcMinutesLeft(dateStr) {
  const d1 = new Date().getTime();
  const d2 = new Date(dateStr).getTime();
  return Math.round((d2 - d1) / 60000);
}

export function objectToFormData(obj) {
  const formData = new FormData();

  // Iterate through the object's keys
  for (let key in obj) {
    // Check if the key is a property of the object (not inherited)
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
}

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

export function createAvtarName(username) {
  return username
    .split(" ")
    .map((el, i) => (i < 2 ? el[0] : ""))
    .join("");
}

export const isAdmin = () => {
  return localStorageInstance.getItem("role") === "admin";
};

export const isManager = () => {
  return localStorageInstance.getItem("role") === "manager";
};

export const isUser = () => {
  return localStorageInstance.getItem("role") === "user";
};

export function addZeroToSingleDigits(number) {
  return number < 10 ? `0${number}` : number;
}

export function checkLateComing(timeString) {
  // console.log("timeString", timeString);
  // Example time: 14:30:00 (2:30 PM)
  if (timeString) {
    const time = timeString?.split(":");

    const hour = time[0];
    const minute = time[1];
    const second = time[2];
    // Create a dayjs instance with the current date and set the time manually
    const timeOnlyInstance = dayjs().hour(hour).minute(minute).second(second);

    const checkTime = dayjs().hour(10).minute(30).second(0);

    // console.log("checkLateComing", timeOnlyInstance.isAfter(checkTime));

    return timeOnlyInstance.isAfter(checkTime);
  }
  return false;
}

export function getTimeDifferenceTillCurrentTime(timeString) {
  // console.log("timeString", timeString);
  // Example time: 14:30:00 (2:30 PM)
  if (timeString) {
    const time = timeString.split(":");

    const hour = time[0];
    const minute = time[1];
    const second = time[2];
    // Create a dayjs instance with the current date and set the time manually
    const inTime = dayjs().hour(hour).minute(minute).second(second);
    // console.log("inTime", inTime);

    const outTime = dayjs();
    // console.log("outTime", outTime);

    const hourTimeDifference = outTime.diff(inTime, "h");
    // console.log("hourTimeDifference", hourTimeDifference);

    const minuteTimeDifference = addZeroToSingleDigits(
      outTime.diff(inTime, "m") % 60
    );
    // console.log("minuteTimeDifference", minuteTimeDifference);

    // console.log(
    //   "actual time diff",
    //   `${hourTimeDifference}:${minuteTimeDifference}`
    // );
    return `${hourTimeDifference}:${minuteTimeDifference}`;
  }
  return null;
}

export function addTotalTime(timeString1, timeString2) {
  if (timeString1 && timeString2) {
    const time1 = timeString1.split(":");
    const h1 = parseInt(time1[0]);
    const m1 = parseInt(time1[1]);
    const time2 = timeString2.split(":");
    const h2 = parseInt(time2[0]);
    const m2 = parseInt(time2[1]);

    // console.log("times", h1, m1, h2, m2);
    // Convert hours to minutes and add both
    const totalMinutes = h1 * 60 + m1 + (h2 * 60 + m2);

    // Convert total minutes back to hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = addZeroToSingleDigits(totalMinutes % 60);

    // console.log("added time", `${hours}:${minutes}`);
    return `${hours}:${minutes}`;
  }
  return null;
}


export function humanizeDateDifference(startDate, endDate) {
  const now = endDate || dayjs();
  return dayjs(startDate).from(now, true); // "true" gives the difference without "ago"
}