import { useQuery } from "@tanstack/react-query";
import {getAttendanceRequest} from '../../../../services/manager/attendance/apiAttendanceRequest'
import { usDateFormate } from "../../../../hooks/dateFormat";


export default function AttendanceRequestQuery(employeeId){
    const { data, isLoading, refetch } = useQuery({
      queryKey: ["attendanceRequest", employeeId],
      queryFn: () => getAttendanceRequest(employeeId),
    });

    const attendanceRequestList = data?.data.attendance_list.map((ele,idx)=>{
        // console.log("is past == ", new Date(ele.date)<new Date())
        return {
            id: idx,
            col1: ele.employee_name,
            col2: ele.application_type,
            col3: ele.in_time,
            col4: ele.out_time,
            col5: usDateFormate(ele.date),
            col6: usDateFormate(ele.applied_on),
            col7: ele.status,
            col8: {isApproved: ele.is_approved, id: ele.application_id, status: ele.status, isPast: new Date()>new Date(ele.date)}
        }
    })

    return {attendanceRequestList, isLoading, refetch}
}