import HolidayList from "../../../components/HolidayList/HolidayList";
import Notices from "../../Dashboard/Notices/Notices";
import Policies from "../../Dashboard/Policies/Policies";

export default function CompanyStuff() {
  return (
    <>
      <HolidayList />
      <Policies />
      <Notices />
    </>
  );
}
