import * as Yup from "yup";

const employeeDetailsSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .max(50, "Name cannot exceed 50 characters"), // Required field

  graduationYear: Yup.string()
    .nullable()
    // .typeError("Graduation Year must be a number")
    // .integer("Graduation Year must be an integer")
    // .min(1900, "Enter a valid year")
    // .max(new Date().getFullYear(), "Graduation Year cannot be in the future")
    .optional(), // Optional field

  education: Yup.string()
    .nullable()
    .trim()
    .max(100, "Education details cannot exceed 100 characters")
    .optional(),

  experienceYear: Yup.string().trim().nullable().optional(),
  experienceMonth: Yup.string().trim().nullable().optional(),

  phoneNumber: Yup.string()
    .trim()
    // .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
    .optional(),

  employeeId: Yup.string().trim().nullable().optional(),

  designation: Yup.string()
    .nullable()
    .trim()
    .max(50, "Designation cannot exceed 50 characters")
    .optional(),

  technology: Yup.string()
    .nullable()
    .trim()
    .max(50, "Technology cannot exceed 50 characters")
    .optional(),

  managerId: Yup.string()
    .trim()
    .max(5, "ManagerId cannot exceed 5 characters")
    .optional(),

  joiningDate: Yup.date()
    .nullable()
    .typeError("Invalid Joining Date")
    .optional(),

  relievingDate: Yup.date()
    .nullable()
    .typeError("Invalid Relieving Date")
    .min(Yup.ref("joiningDate"), "Relieving Date cannot be before Joining Date")
    .optional(),

  documentLink: Yup.string()
    .nullable()
    // .matches(
    //   /^https:\/\/drive\.google\.com\//,
    //   "The URL must start with 'https://drive.google.com/'"
    // )
    .optional(),

  gender: Yup.string()
    .oneOf(["Male", "Female"], "Invalid gender option")
    .optional(),

  dob: Yup.date().nullable().typeError("Invalid Date of Birth").optional(),

  currentAddress: Yup.string()
    .nullable()
    .max(200, "Address cannot exceed 200 characters")
    .optional(),

  currentCity: Yup.string()
    .nullable()
    .max(50, "City cannot exceed 50 characters")
    .optional(),

  currentState: Yup.string()
    .nullable()
    .max(50, "State cannot exceed 50 characters")
    .optional(),

  currentZip: Yup.string()
    .nullable()
    // .matches(/^[0-9]{5,6}$/, "Enter a valid ZIP code")
    .optional(),

  permanentAddress: Yup.string()
    .nullable()
    .max(200, "Address cannot exceed 200 characters")
    .optional(),

  permanentCity: Yup.string()
    .nullable()
    .max(50, "City cannot exceed 50 characters")
    .optional(),

  permanentState: Yup.string()
    .nullable()
    .max(50, "State cannot exceed 50 characters")
    .optional(),

  permanentZip: Yup.string()
    .nullable()
    // .matches(/^[0-9]{5,6}$/, "Enter a valid ZIP code")
    .optional(),

  adharCard: Yup.string()
    .nullable()
    .trim()
    // .matches(/^[0-9]{12}$/, "Aadhar must be a 12-digit number")
    .optional(),

  panCard: Yup.string()
    .nullable()
    .trim()
    // .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Enter a valid PAN number")
    .optional(),

  driverLicense: Yup.string()
    .nullable()
    .trim()
    .max(20, "Driving License cannot exceed 20 characters")
    .optional(),

  pfAccount: Yup.string()
    .nullable()
    .trim()
    .max(25, "PF Account cannot exceed 25 characters")
    .optional(),

  esicNo: Yup.string()
    .nullable()
    .trim()
    .max(25, "ESIC cannot exceed 25 characters")
    .optional(),

  ldap: Yup.string()
    .nullable()
    .trim()
    .max(20, "LDAP cannot exceed 20 characters")
    .optional(),

  fatherName: Yup.string()
    .nullable()
    .trim()
    .max(50, "Father's Name cannot exceed 50 characters")
    .optional(),

  motherName: Yup.string()
    .nullable()
    .trim()
    .max(50, "Mother's Name cannot exceed 50 characters")
    .optional(),

  documents: Yup.mixed()
    .nullable()
    .test(
      "fileSize",
      "File size is too large",
      (value) => !value || value.size <= 3 * 1024 * 1024
    )
    .test(
      "fileType",
      "Unsupported File Format",
      (value) =>
        !value || ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    )
    .optional(),

  status: Yup.string()
    .oneOf(["Active", "Inactive"], "Invalid status option")
    .optional(),

  permission: Yup.array().of(Yup.string()).optional(),
});

export default employeeDetailsSchema;
