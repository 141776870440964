import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataGridComp from "../../../../components/DataGrid/DataGrid";
import styles from "./styles.module.css";
// import { useResponsive } from "../../../../hooks/customUseResponsive";
import { LeaveStatementQuery } from "../../../../utils/reactQuery/leave/LeaveStatement";
import { getFinancialYear } from "../../../../services/leave/apiFinancialYear";
import { FinancialYearQuery } from "../../../../utils/reactQuery/leave/FinancialYear";
import { LoadingButton } from "@mui/lab";
import downloadIcon from "../../../../styles/icons/downloadIcon.svg";
// import { useLocation } from "react-router-dom";

const DownloadButtonIcon = (
  <Box
    component="img"
    src={downloadIcon}
    alt="downloadIcon"
    height={28}
    width={28}
  />
);

function LeaveStatement({ employeeId, showButton, updateColumnWidth  }) {
  // const location = useLocation();
  // const isDashboard = location.pathname.includes("dashboard");

  const [currentYear, setCurrentYear] = useState(
    `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`
  );
  const [previewPdf, setPreviewPdf] = useState(false);
  // const isMobile = useResponsive("down", "sm");
  const { leaveStatement, isLoading } = LeaveStatementQuery(
    currentYear,
    employeeId
  );
  const { FinancialYear } = FinancialYearQuery();

  const leaveBalance = leaveStatement?.[0]?.leaveBalance;
  // console.log("leaveBalance", leaveBalance);
  // console.log("LeaveStatement currentEmployee", employeeId);

  const columns = [
    {
      field: "col1",
      headerName: "Month & Year",
      headerClassName: `${
        updateColumnWidth ? styles.columnWidth3 : styles.columnWidth2
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth3 : styles.columnWidth2
      }`,
    },
    {
      field: "col2",
      headerName: "CL",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col3",
      headerName: "CL",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col4",
      headerName: "CL Used",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col5",
      headerName: "LC",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col6",
      headerName: "EL",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col7",
      headerName: "LWP",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col8",
      headerName: "Encashed",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col9",
      headerName: "Comp Off",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
    {
      field: "col10",
      headerName: "CL",
      headerAlign: "right",
      align: "right",
      headerClassName: `paddingClass ${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      cellClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "2024-2025",
      description: "",
      headerClassName: `${
        updateColumnWidth ? styles.columnWidth3 : styles.columnWidth2
      }`,
      children: [{ field: "col1" }],
    },
    {
      groupId: "Opening Balance",
      description: "",
      headerClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      children: [{ field: "col2" }],
    },
    {
      groupId: "Alloted",
      description: "",
      headerClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      children: [{ field: "col3" }],
    },
    {
      groupId: "Adjustments",
      headerClassName: `${
        updateColumnWidth
          ? styles.columnGroupHeader1
          : styles.columnGroupHeader2
      }`,
      children: [
        { field: "col4" },
        { field: "col5" },
        { field: "col6" },
        { field: "col7" },
        { field: "col8" },
        { field: "col9" },
      ],
    },
    {
      groupId: "Closing Balance",
      description: "",
      headerClassName: `${
        updateColumnWidth ? styles.columnWidth2 : styles.columnWidth1
      }`,
      children: [{ field: "col10" }],
    },
  ];

  const handlePreviewPdf = () => {
    setPreviewPdf(true);
    const previewUrl = "/leave-statement-preview";

    // Store the data in localStorage
    localStorage.setItem("pdfData", JSON.stringify(leaveStatement));
    localStorage.setItem("currentYear", currentYear);

    // Create a new tab and pass the state
    window.open(previewUrl, "_blank");

    setPreviewPdf(false);
  };

  useEffect(() => {
    getFinancialYear()
      .then(() => {})
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box className={styles.cont}>
      <Stack direction="row" className={styles.container}>
        <Stack className={styles.shortFormCont} direction="row">
          <Stack direction="row" className={styles.subCont}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>CL </b>- Casual Leave
            </Typography>
          </Stack>

          <Stack
            direction="row"
            className={[styles.subCont, styles.lateComMobile]}
          >
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>LC </b>- Late Coming
            </Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>EL </b>- Early Leaving
            </Typography>
          </Stack>

          <Stack direction="row" className={styles.subCont}>
            <Box className={styles.indicator} />
            <Typography className={styles.shortFormText}>
              <b>LWP </b>- Leave Without Pay
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" className={styles.financialSection}>
          <Typography className={styles.labelFinance}>
            Select Financial Year
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentYear}
            onChange={(event) => {
              setCurrentYear(event.target.value);
            }}
            className={styles.selectField}
          >
            {FinancialYear
              ? FinancialYear.map((ele, idx) => (
                  <MenuItem
                    className={styles.menuItem}
                    value={ele.value}
                    key={idx}
                  >
                    {ele.year}
                  </MenuItem>
                ))
              : []}
          </Select>

          {showButton && (
            <LoadingButton
              variant="contained"
              startIcon={DownloadButtonIcon}
              loading={previewPdf}
              className={styles.downloadButton}
              onClick={handlePreviewPdf}
            >
              Download Pdf
            </LoadingButton>
          )}
        </Stack>
      </Stack>

      <Box
        className={styles.datagridCon}
        sx={{
          "& .paddingClass": {
            paddingRight: "10px !important",
          },
        }}
      >
        {isLoading && (
          <Stack className={styles.loadingCont}>
            <CircularProgress />
          </Stack>
        )}
        <DataGridComp
          columns={columns}
          initialRows={leaveBalance || []}
          headerBgColor="#FFFFFF"
          columnGroupingModel={columnGroupingModel}
          footer={true}
          pageSize={12}
          setNoDataWidth={true}
        />
      </Box>
    </Box>
  );
}

export default LeaveStatement;
