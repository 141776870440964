import { Box, Stack } from "@mui/material";
import ManagerEmployeesQuery from "../../../utils/reactQuery/manager/ManagerEmployees";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import Loader from "../../../components/Loader";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import { usDateFormate } from "../../../hooks/dateFormat";

export default function ManagerEmployees() {
  const { employees, isLoading } = ManagerEmployeesQuery();

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: 120,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Joining Date",
      minWidth: 120,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{usDateFormate(value.value)}</span>,
    },
    {
      field: "col3",
      headerName: "Position",
      minWidth: 120,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Mobile Number",
      minWidth: 120,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];
  return (
    <Box className={styles.mainContainer}>
      <TableHeader heading="Employees Detail" />
      <Box className={styles.dataGridContainer}>
        {isLoading && (
          <Stack className={styles.manageEmployeesLoaderCont}>
            <Loader />
          </Stack>
        )}
        <DataGridComp columns={columns} initialRows={employees || []} />
      </Box>
    </Box>
  );
}
