import Api from "../axios";

export default async function apiEditHoliday({
  id,
  holidayDate,
  holidaySubject,
}) {
  // console.log("api payload", id, holidayDate, holidaySubject);
  return await Api.post("/edit_holiday", {
    holiday_id: id,
    date: holidayDate,
    subject: holidaySubject,
  });
}
