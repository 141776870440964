import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@tanstack/react-query";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { removeHtmlTagsAndEntities } from "../../../../hooks/removehtmlTags";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import RHFTextField from "../../../../components/RFH/RFHtextfield";
import AddPolicySchema from "../../../../schemas/addPolicySchema";
import { addPolicy } from "../../../../services/policy/apiAddPolicy";
// import { RHFPolicyFileUploader } from "../../../../components/RFH/RFHfileUploader";

export default function AddPolicyModal({ openProp, handleClose, refetch }) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 808,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    scrollY: "scroll",
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      ["link"],
      ["clean"],
    ],
  };
  const defaultValues = {
    policy_name: "",
    description: "",
    // fileupload: null,
  };

  const methods = useForm({
    resolver: yupResolver(AddPolicySchema),
    defaultValues,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: addPolicy,
    onSuccess: (data) => {
      toast.success(data.data.message);
      methods.reset();
      handleClose();
      refetch();
    },
    onError: (data) => {
      console.log("error adding notice", data);
      toast.error(
        "Something went wrong while adding the policy.\nPlease try again."
      );
    },
  });

  // console.log("errors === ", methods.formState.errors);

  const onSubmit = (data) => {
    // console.log("data === ", data);
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    // console.log("formData", ...formData);
    mutate(formData);
  };

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add Policy</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>

            <Box className={styles.container}>
              <Stack className={styles.contSelect}>
                <Box className={styles.flexItem}>
                  <Typography className={styles.label}>Policy Name</Typography>
                  <RHFTextField
                    name="policy_name"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: isMobile
                          ? "30px !important"
                          : "40px !important",
                        backgroundColor: "#fff",
                        fontSize: isMobile ? "12px !important" : "inherit",
                      },
                    }}
                  />
                </Box>
              </Stack>
              <Typography className={styles.label}>Description</Typography>
              <Box className={styles.textEditorCont}>
                <ReactQuill
                  value={methods.getValues("description")}
                  onChange={(value) =>
                    methods.setValue(
                      "description",
                      removeHtmlTagsAndEntities(value).length === 0 ? "" : value
                    )
                  }
                  modules={modules}
                  style={{ height: "170px" }}
                />
              </Box>
              <Typography className={styles.feedbackError}>
                {methods.formState.errors.description?.message}
              </Typography>
              {/* <Box>
                <Typography className={styles.label}>Attachments</Typography>
                <RHFPolicyFileUploader name="fileupload" />
              </Box> */}
              <Stack className={styles.btnCont}>
                <LoadingButton
                  loading={isPending}
                  type="submit"
                  className={styles.btnClass}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>

                <Button
                  className={styles.btnClass}
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
