import * as Yup from 'yup';

const AddNoticeSchema = Yup.object().shape({
    policy_name: Yup.string().required('Notice name is required'),
    description: Yup.string().required('Description is required'),
    // file: Yup.mixed()
    // .required('A file is required')
    // .test('fileSize', 'File Size is too large', (value) => {
    //   return value && value[0] && value[0].size <= 2000000; // 2MB
    // })
    // .test('fileType', 'Unsupported File Format', (value) => {
    //   return value && value[0] && ['image/jpeg', 'image/png'].includes(value[0].type);
    // }),
});

export default AddNoticeSchema;
