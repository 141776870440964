import { Navigate, Outlet } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import { isManager, isUser } from "../../utils/helpers";
import ForbiddenView from "../../pages/Forbidden";

export default function Private() {
  let auth = localStorageInstance.getItem("token");

  return auth ? <Outlet /> : <Navigate to="/" />;
}

export function ForbidUserAccess({ children }) {
  return isUser() ? <ForbiddenView /> : children;
}

export function ForbidManagerUserAccess({ children }) {
  return isUser() || isManager() ? <ForbiddenView /> : children;
}
