import { useQuery } from "@tanstack/react-query";
import getPresentMonthLeave from "../../services/count/apiPresentMonthLeave";

export function PresentMonthLeaves(employeeId) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["presentMonthLeaves", employeeId],
    queryFn: () => getPresentMonthLeave(employeeId),
  });

  const presentMonthLeaves = data?.data.total_leave;

  return { presentMonthLeaves, isLoading, isError };
}