import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import App from "./App";

// import { Provider } from "react-redux";
// import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
  >
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}
  </ErrorBoundary>
);
